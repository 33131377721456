<template>
	<SectionHeader :image="null" title="Rechtliche Informationen" page="Impressum" />
	<div class="container">
		<h3 class="fw-light">Kreisverband Reutlingen Landsmannschaft der Banater Schwaben e.V.</h3>
		<dt>Anschrift</dt>
		<dl>Bruno-Matzke-Str. 10<br/>
		D-72770 Reutlingen</dl>

		<dt>Telefon</dt>
		<dl>+49 7121 123 456</dl>
		<dt>E-Mail</dt>
		<dl>vorstand@bs-rt.de</dl>

		<p>Gemeinschaftlich vertretungsbefugt:</p>
		<dt>1. Vorsitzende</dt>
		<dl>Theresia Christine Neu</dl>

		<dt>Das Impressum gilt für:</dt>
		<dl>https://www.bs-rt.de <br/>
		https://facebook.com/banater-schwaben-reutlingen</dl>

		<dt>Registergericht</dt>
		<dl>Amtsgericht Stuttgart</dl>
		<dt>Registernummer</dt>
		<dl>VR 724277</dl>

	</div>
</template>
<script>
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: 'Impressum',
	components: {
		SectionHeader
	},
	mounted () {
		window.scrollTo(0, 0)
	}
}
</script>
